import React from 'react';
import { updateSystemData } from '../../../store/actions/systemActions';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Close } from '../../../images/close.svg'
import { ReactComponent as Back } from '../../../images/back-small.svg'

export const BlockedUser = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const systemData = useSelector((state) => state.systemReducer.systemData)

    const setModalClose = () => {
        navigate(systemData.isCheckout ? '/checkout' : '/')
    };

    const handleBack = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'loginOptions' }
        }));
    }
    return <>
        {/* <Close onClick={setModalClose} /> */}
        <div className="header-btns">
            <button type="button" className="back-btn" onClick={handleBack}>
                <p>חזרה</p>
                <Back />
            </button>
            {/*<Close onClick={setModalClose} />*/}
        </div>
        <div className="content blocked-msg">
            <div className="msg-content ">
                <h2 className="main-title bold">נחסמתם מהמערכת</h2>
                <p className="decription">
                    <br />
                    נחסמת ע"י משתמש בעל הרשאות בחברה שלך, לבירורים נוספים נא ליצור קשר עם מנהלי החברה.
                </p>

                {/* <p className="decription">
                    לא קיבלתם דוא״ל? <span><u>לשליחה חוזרת</u></span>
                </p> */}

                {/* {errorAuth && <p className="error-message">{errorAuth}</p>} */}

            </div>
            <button type='submit' onClick={() => navigate('/')} className='main-btn long register' >חזרה למסך הראשי</button>
        </div>
    </>

    return <div className="email-sent">
        {/* <Close onClick={setModalClose} /> */}
        <div className="system-msg blocked-msg">
            <div className="header-btns">
                <button type="button" className="back-btn" onClick={handleBack}>
                    <p>חזרה</p>
                    <Back />
                </button>
                {/*<Close onClick={setModalClose} />*/}
            </div>

            <div className="msg-content" style={{ margin: '0' }}>
                <h2 className="main-title bold">נחסמתם מהמערכת</h2>
                <p className="decription">
                    <br />
                    נחסמת ע"י משתמש בעל הרשאות בחברה שלך, לבירורים נוספים נא ליצור קשר עם מנהלי החברה שלך.
                </p>

                <p className="decription">
                    {/* לא קיבלתם דוא״ל? <span><u>לשליחה חוזרת</u></span> */}
                </p>

                {/* {errorAuth && <p className="error-message">{errorAuth}</p>} */}

            </div>
            <button type='submit' onClick={() => navigate('/')} className='main-btn long register' >חזרה למסך הראשי</button>
        </div>
    </div>
};


