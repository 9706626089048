import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { updateSystemData } from '../../../store/actions/systemActions';
import secure from "../../../images/secure.svg"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Field, Formik } from 'formik';
import { validationLogin } from '../validations'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Close } from '../../../images/close.svg'
import { ReactComponent as Back } from '../../../images/back-small.svg'
import { auth } from '../../../integrations/firebase'
import { doc, getDoc } from "@firebase/firestore";
import db, { Logger } from '../../../integrations/firebase'
import { login } from "../../../store/actions/userActions"

export const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const [errorAuth, setErrorAuth] = useState(null)

    const onForgotPassword = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'resetPassword' }
        }));
    }



    const onSubmit = (values) => {
        signInWithEmailAndPassword(auth, values.email, values.password)
            .then(async (userCredential) => {
                // Signed in 
                const user = userCredential.user;
                const userInvitesRef = doc(db, "invites", 'awaitingUserInvites');
                const userInvites = await getDoc(userInvitesRef);
                const userInvitesData = userInvites.data();

                const isUserOfCompany = userInvitesData[user.uid] ? userInvitesData[user.uid] : ''
                if (isUserOfCompany) {
                    dispatch(login(user))
                    dispatch(updateSystemData({
                        ...systemData, currLoginData: { currentStep: 'firstLogin' }
                    }));
                    navigate('/LoginSignup')
                }
                else {
                    const userRef = doc(db, "users", user.uid);
                    const dbUser = await getDoc(userRef)
                    if (dbUser.data()?.userActivity === 'blocked') {
                        dispatch(updateSystemData({
                            ...systemData, currLoginData: { currentStep: 'blockedUser' }
                        }));
                        navigate('/LoginSignup')
                    }
                    else {
                        dispatch(login({ ...user, ...dbUser?.data()}))
                        navigate('/')
                        window.location.reload()
                    }
                }
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setErrorAuth('דוא״ל או סיסמה אינם נכונים')
                console.log('%c  errorMessage:', 'color: white;background: red;', errorMessage);
            });
    }

    const onSignupWithEmail = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'signupDetails' }
        }));
    }

    const setModalClose = () => {
        navigate(systemData.isCheckout ? '/checkout' : '/')
    };

    const handleBack = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'loginOptions' }
        }));
    }

    return <>
        <div className="header-btns">
            <button type="button" className="back-btn" onClick={handleBack}>
                <p>חזרה</p>
                <Back />
            </button>
            {/*<Close onClick={setModalClose} />*/}
        </div>
        <div className="content">

            <div className="login-step">
                <h2 className="main-title login">כניסה לחשבון שלך</h2>
                <Formik
                    onSubmit={onSubmit}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    enableReinitialize={true}
                    validationSchema={validationLogin}
                >
                    {(props) => <form onSubmit={props.handleSubmit}>
                        <Field
                            placeholder='כתובת דוא"ל*'
                            className={(props.touched.email) && Boolean(props.errors.email) ? 'signup-input error' : 'signup-input'}
                            type='text'
                            name='email'
                            onChange={props.handleChange}
                            value={props.values.email}
                        />

                        <Field
                            type='password'
                            className={(props.touched.password) && Boolean(props.errors.password) ? 'signup-input error' : 'signup-input'}
                            placeholder='סיסמה*'
                            value={props.values.password}
                            onChange={props.handleChange}
                            name='password'

                        />

                        <div className="forgot">
                            <div>
                                <input type="checkbox" name="remember" id="remember" />
                                <label htmlFor="remember">זכור אותי</label>
                            </div>
                            <p onClick={() => onForgotPassword(props.values.email)} className="forgot-link">שכחתי את הפרטים שלי</p>
                        </div>
                        {errorAuth && <p className="error-message">{errorAuth}</p>}
                        <button type='submit' className='main-btn long register' >התחברות</button>
                    </form>}
                </Formik>
                <div className="security-note">
                    <div>
                        <img src={secure} alt="secure" />
                        <p>המידע שלכם שמור בבטחה</p>
                    </div>
                    <p>רכישה מאובטחת בתקן PCI DSS</p>
                </div>
            </div >
        </div>
    </>
};


