import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { updateSystemData } from '../../../store/actions/systemActions';
import secure from "../../../images/secure.svg"
import { Field, Formik } from 'formik';
import { validationOfficeDetails } from '../validations'
import { doc, setDoc } from "@firebase/firestore";
import db, { Logger } from '../../../integrations/firebase'
import { ReactComponent as Close } from '../../../images/close.svg'
import { ReactComponent as Back } from '../../../images/back-small.svg'
import { sendEmailVerification } from "firebase/auth";
import { useNavigate } from 'react-router-dom'
import { createUser } from '../../../integrations/firebase';
import { setRegisteredUser, login } from '../../../store/actions/userActions';
import { auth, actionCodeSettings } from '../../../integrations/firebase'
import { setIsLoading } from '../../../store/actions/systemActions'
import { TailSpin } from 'react-loader-spinner';
const logClient = new Logger(`OfficeDetails`);
export const OfficeDetails = () => {
    const navigate = useNavigate();
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const isLoading = useSelector((state) => state.systemReducer.isLoading)
    const registeredUser = useSelector((state) => state.userReducer.registeredUser);
    const activeUser = useSelector((state) => state.userReducer.user);
    const utm = useSelector((state) => state.utmReducer);
    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(false)
    const officeDetails = {
        email: registeredUser.email,
        fullName: registeredUser.fullName,
        phoneNumber: registeredUser.phoneNumber,
        accountType: registeredUser.accountType,
        isLawyer: registeredUser.isLawyer,
        lawyerNumber: registeredUser.lawyerNumber,
        officeOrCompanyName: registeredUser?.officeOrCompanyName || '',
        officeOrCompanyId: registeredUser?.officeOrCompanyId || '',
        officeBookkeepingEmail: registeredUser?.officeBookkeepingEmail || '',
    }

    const handleChange = (event) => {
        dispatch(setRegisteredUser({
            ...registeredUser,
            [event.target.name]: event.target.value
        }))
    }

    const onSubmit = async (values) => {
        if (isSubmitted) return
        setIsSubmitted(true)
        dispatch(setIsLoading(true))
        const user = {
            email: registeredUser.email,
            fullName: registeredUser.fullName,
            phoneNumber: registeredUser.phoneNumber,
            accountType: registeredUser.accountType,
            isLawyer: registeredUser.isLawyer,
            lawyerNumber: registeredUser.lawyerNumber,
            officeOrCompanyName: values.officeOrCompanyName,
            officeOrCompanyId: values.officeOrCompanyId,
            officeBookkeepingEmail: values.officeBookkeepingEmail,
            addresses: [],
            signUpCode: systemData.signUpCode || null,
            utm
        }
        const userRef = doc(db, "users", activeUser.uid);
        await setDoc(userRef, user).then((r) => {}).catch((e) => {
            logClient.error('error when updating user data');
            logClient.error(JSON.stringify(user))
            logClient.error(JSON.stringify(e));
        });
        await createUser({})
        if (!auth.currentUser.emailVerified) {
            sendEmailVerification(auth.currentUser, actionCodeSettings)
                .then(() => { });
        }
        dispatch(login({ ...activeUser, ...user }))
        dispatch(setIsLoading(false))
        navigate('/welcome')
        window.location.reload()
    }
    const setModalClose = () => {
        navigate(systemData.isCheckout ? '/checkout' : '/')
    };

    const handleBack = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'signupDetails' }
        }));
    }

    return <>
        <div className="header-btns">
            <button type="button" className="back-btn" onClick={handleBack}>
                <p>חזרה</p>
                <Back />
            </button>
            {/*<Close onClick={setModalClose} />*/}
        </div>
        <div className="content">
            <div className="login-step">
                <h2 className="main-title">{registeredUser.accountType === 'משרד עורכי דין' ? 'פרטי המשרד' : 'פרטי החברה'}</h2>
                <p>יש להזין את שם {registeredUser.accountType === 'משרד עורכי דין' ? ' המשרד, מספר העוסק ודוא״ל לקבלת חשבוניות ' : ' החברה, מספר העוסק ודוא״ל לקבלת חשבוניות'}</p>
                <Formik
                    onSubmit={onSubmit}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                    initialValues={{
                        officeOrCompanyName: officeDetails?.officeOrCompanyName || '',
                        officeOrCompanyId: officeDetails?.officeOrCompanyId || '',
                        officeBookkeepingEmail: officeDetails?.officeBookkeepingEmail || '',
                    }}
                    enableReinitialize={true}
                    validationSchema={validationOfficeDetails}
                >
                    {(props) =>
                        <form onSubmit={props.handleSubmit}>
                            <div className='field'>
                                <label>
                                    {registeredUser.accountType === 'משרד עורכי דין' ? 'שם המשרד*' : 'שם החברה*'}
                                </label>
                                <Field
                                    className={(props.touched.officeOrCompanyName) && Boolean(props.errors.officeOrCompanyName) ? 'signup-input error' :
                                        props.values.officeOrCompanyName.length > 0 ? 'signup-input success' : 'signup-input'}
                                    type='text'
                                    name='officeOrCompanyName'
                                    onChange={(e) => { props.handleChange(e); handleChange(e) }}
                                    value={props.values.officeOrCompanyName}
                                />
                            </div>
                            <div className='field'>
                                <label>
                                    דוא״ל להנהלת חשבונות*
                                </label>
                                <Field
                                    type='text'
                                    className={(props.touched.officeBookkeepingEmail) && Boolean(props.errors.officeBookkeepingEmail) ? 'signup-input error' :
                                        props.values.officeBookkeepingEmail.length > 0 ? 'signup-input success' : 'signup-input'}
                                    onChange={(e) => { props.handleChange(e); handleChange(e) }}
                                    name='officeBookkeepingEmail'
                                    value={props.values.officeBookkeepingEmail}
                                />
                            </div>
                            <div className='field'>
                                <label>
                                    ע.מ. / ח.פ.
                                </label>
                                <Field
                                    className={(props.touched.officeOrCompanyId) && Boolean(props.errors.officeOrCompanyId) ? 'signup-input error' :
                                        props.values.officeOrCompanyId.length > 0 ? 'signup-input success' : 'signup-input'}
                                    type='text'
                                    name='officeOrCompanyId'
                                    onChange={(e) => { props.handleChange(e); handleChange(e) }}
                                    value={props.values.officeOrCompanyId}
                                />
                            </div>
                            {/* <div className="separator-long"></div> */}
                            <button type='submit' className='main-btn long center'>{!isLoading ?
                                'סיום הרשמה' :
                                <TailSpin
                                    heigth="50"
                                    width="50"
                                    color='#ffffff'
                                    ariaLabel='loading'
                                />}</button>
                        </form>
                    }


                </Formik>

                <div className="security-note">
                    <div>
                        <img src={secure} alt="secure" />
                        <p>המידע שלכם שמור בבטחה</p>
                    </div>
                    <p>רכישה מאובטחת בתקן PCI DSS</p>
                </div>
            </div >
        </div>
    </>
};


