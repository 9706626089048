import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { setMobileState } from '../store/actions/systemActions';
import SideLogo from "../images/Group 4157.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logout } from '../images/logout.svg'
import { signInAnonymously, signOut } from "firebase/auth";
import { login } from '../store/actions/userActions'
import { setCompany } from '../store/actions/companyActions'
import { auth } from '../integrations/firebase'
import { useViewport } from '../Hooks/useViewport'
import db, { trace, perf } from '../integrations/firebase';
import { updateSystemData } from '../store/actions/systemActions';

export const AppMenu = () => {
    const t = trace(perf, "AllActions");
    t.start();
    const dispatch = useDispatch();
    const mobile = useSelector((state) => state.mobileReducer.mobileState);
    const activeUser = useSelector((state) => state.userReducer.user);
    const systemData = useSelector((state) => state.systemReducer.systemData)

    const { width } = useViewport();
    const breakpoint = 1111;
    const isMobile = width < breakpoint;

    const navigate = useNavigate();
    const ref = useRef();

    useEffect(() => {
        const onBodyClick = (event) => {
            if (ref.current.contains(event.target) || !mobile.isMenuOpen) {
                return;
            }
            dispatch(setMobileState({
                ...mobile,
                isMenuOpen: false,
                time: Date.now()
            }))
        };
        document.body.addEventListener("click", onBodyClick, { capture: true });

        return () => {
            document.body.removeEventListener("click", onBodyClick, {
                capture: true,
            });
        };
    }, [mobile.isMenuOpen]);

    const onCloseMenu = () => {
        navigate('/')
        dispatch(setMobileState({
            ...mobile,
            isMenuOpen: false
        }))
    }

    const onSignOut = () => {
        // signInAnonymously(auth)
        signOut(auth)
        // const user = auth.currentUser;
        dispatch(setCompany(null))
//        dispatch(login(null))
        navigate('/LoginSignup')
    }


    const returnValue = (
        <div className="wrapper">
            <div className="background"></div>
            <div ref={ref} className={`${mobile.isMenuOpen ? "side-menu open" : "side-menu"}`}>
                <img className="side-appbar-logo" src={SideLogo} alt="logo" onClick={onCloseMenu} />
                <button className="new-order" onClick={onCloseMenu}>הזמנה חדשה</button>

                <div className="links-container">

                    {activeUser?.fullName && <ul className="side-app-bar-links">
                        <li className="link-item"><Link to="user/orders" onClick={onCloseMenu} >ההזמנות שלי</Link></li>
                        {isMobile && <li className="link-item"><Link to="user/msgs" onClick={onCloseMenu} >הודעות ועדכונים</Link></li>}
                        <li className="link-item"><Link to="user/details" onClick={onCloseMenu}>פרטים אישיים</Link></li>
                        <li className="link-item" ><Link to="user/addresses" onClick={onCloseMenu}>הכתובות שלי</Link></li>
                        {/* <li className="link-item" ><Link to="user/invoices" onClick={onCloseMenu}>חשבוניות</Link></li> */}
                        {activeUser?.isMaster && activeUser?.accountType !== 'חשבון פרטי' &&
                            <li className="link-item" ><Link to="user/company" onClick={onCloseMenu}>החברה שלי</Link></li>
                        }
                    </ul>}

                    <ul className="side-app-bar-links">
                        <li className="link-item">
                            <a href="https://delawvery.co.il/legal-deliveries/" rel="noreferrer" target="_blank">השירותים שלנו</a>
                        </li>
                        <li className="link-item">
                            <a href="https://delawvery.co.il/prices/" rel="noreferrer" target="_blank">מסלולי לקוחות</a>
                        </li>
                        <li className="link-item">
                            <a href="https://delawvery.co.il/service-points/" rel="noreferrer" target="_blank">נקודות שירות</a>
                        </li>
                        <li className="link-item">
                            <a href="https://delawvery.co.il/about/" rel="noreferrer" target="_blank">אודות</a>
                        </li>
                        <li className="link-item">
                            <a href="https://delawvery.co.il/customers/" rel="noreferrer" target="_blank">בין לקוחותינו</a>
                        </li>
                        <li className="link-item">
                            <a href="https://delawvery.co.il/%d7%94%d7%a6%d7%95%d7%95%d7%aa-%d7%a9%d7%9c%d7%a0%d7%95/" rel="noreferrer" target="_blank"> הצוות שלנו</a>
                        </li>
                        <li className="link-item">
                            <a href="https://delawvery.co.il/faq/" rel="noreferrer" target="_blank">שאלות ותשובות</a>
                        </li>
                        <li className="link-item">
                            <a href="https://delawvery.co.il/blog/" rel="noreferrer" target="_blank">בלוג</a>
                        </li>
                        <li className="link-item">
                            <a href="https://delawvery.co.il/contact/" rel="noreferrer" target="_blank">יצירת קשר</a>
                        </li>
                    </ul>
                    <ul className="side-app-bar-links small">
                        <li className="link-item-small">
                            <a href="https://delawvery.co.il/terms-of-use/" rel="noreferrer" target="_blank">תקנון שימוש  </a>
                        </li>
                        <li className="link-item-small">
                            <a href="https://delawvery.co.il/privacy-policy/" rel="noreferrer" target="_blank">מדיניות פרטיות</a>
                        </li>
                        <li className="link-item-small">
                            <a href="https://delawvery.co.il/accessibility-statement/" rel="noreferrer" target="_blank">הצהרת נגישות </a>
                        </li>
                    </ul>
                    <ul className="side-app-bar-links small">
                        <li>
                            {activeUser?.fullName && <div className="logout" onClick={onSignOut}>
                                <Logout />
                                <p >התנתקות מהמערכת</p>
                            </div>}
                        </li>
                    </ul>
                </div >
            </div >
        </div >
    )
    t.stop()
    return returnValue
}
