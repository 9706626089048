import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ReactComponent as Close } from '../../images/close.svg'
import { AddOrEditAddress } from '../UserDashboard/AddOrEditAddress'
import TagManager from 'react-gtm-module'
import db, { trace, perf} from '../../integrations/firebase';

export function NewUser() {
    const t = trace(perf, "AllActions");
    t.start();
    const navigate = useNavigate();
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const [editAddress, setEditAddress] = useState(null)

    const tagManagerArgs = {
        gtmId: 'GTM-N57K36G',
        dataLayer: {
            newUser: 'newUser',
        }
    }

    TagManager.initialize(tagManagerArgs)

    const setModalClose = () => {
        navigate(systemData.isCheckout ? '/checkout' : '/')
        window.location.reload()
    };

    const returnValue = (
        <div className="pop-up-login">
            <div className="container">
                <div className="welcome">

                    {/*<Close onClick={setModalClose} />*/}
                    <div className="system-msg">
                        <div className="msg-content">
                            <h2 className="main-title bold">ברוכים הבאים לדלוברי!</h2>
                            <AddOrEditAddress editAddress={editAddress} setEditAddress={setEditAddress} isFromNewUser={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    t.stop();
    return returnValue
}


