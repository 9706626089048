import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { updateSystemData } from '../../../store/actions/systemActions';
import google from '../../../images/google.svg'
import facebook from '../../../images/fb.svg'
import secure from "../../../images/secure.svg"
import { GoogleAuthProvider, signInWithPopup, FacebookAuthProvider, createUserWithEmailAndPassword } from "firebase/auth";
import { setRegisteredUser } from '../../../store/actions/userActions'
import { Field, Formik } from 'formik';
import { validationSignupPage } from '../validations.js'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Close } from '../../../images/close.svg'
import { auth, actionCodeSettings } from '../../../integrations/firebase'
import { sendEmailVerification } from "firebase/auth";

export const SignupOptions = (hideLoginButton = false) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const isDelawverySignup = false
    const provider = new GoogleAuthProvider();
    const fbProvider = new FacebookAuthProvider();
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [errorAuth, setErrorAuth] = useState(null)
    
    useEffect(() => {
        return () => {
            sessionStorage.setItem('isDelawverySignup', JSON.stringify(false));
        };
    }, []);

    const onLogin = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'loginOptions' }
        }));
    }

    const login = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'login' }
        }));
        navigate('/LoginSignup')
    }

    const onForgotPassword = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'resetPassword' }
        }));
        navigate('/LoginSignup')
    }

    const onFacebookLogin = () => {
        signInWithPopup(auth, fbProvider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                dispatch(setRegisteredUser(user))
                navigate('/')
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });

    }

    const onGoogleLogin = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                dispatch(setRegisteredUser(user))
                navigate('/')
                // sendEmailVerification(auth.currentUser, actionCodeSettings)
                //     .then(() => {
                //         // Email verification sent!
                //         // ...
                //     });
                // onSignupDetails()
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.log({
                    errorCode,
                    errorMessage,
                    email,
                    credential
                })
            });
    }

    const onSubmit = async (values, { resetForm }) => {
        console.log('Submit')
        createUserWithEmailAndPassword(auth, values.mail, values.password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                dispatch(setRegisteredUser({ ...user, email: values.mail }))
                // navigate('/')
                dispatch(updateSystemData({
                    ...systemData, currLoginData: { currentStep: 'signupDetails' }
                }));
                navigate('/LoginSignup')
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('%c  errorMessage:', 'color: white;background: red;', errorMessage);
                setErrorAuth('קיים משתמש עם הדוא״ל שהוזן')
            });
    };

    const setModalClose = () => {
        navigate(systemData.isCheckout ? '/checkout' : '/')
    };

    return <>
        <div className="header-btns">
            {isDelawverySignup ? <div></div> :
                <button type='button' style={hideLoginButton.hideLoginButton ? { "visibility": "hidden" } : { "visibility": "visible" }} className='orange-btn' onClick={onLogin}>
                    התחברות
                </button>
            }
        </div>
        <div className="content">
            <div className="login-step">
                {/* <button type='button' className='login-signup' onClick={() => onLogin()} > התחברות</button> */}

                {isDelawverySignup ? <div className='titles'>
                    <h2 className="main-title">נרשמים ומקבלים הרבה יותר!</h2>
                    <h3 className='sub-title'>ללא עלות נוספת וללא התחייבות</h3>
                </div> :
                    <h2 className="main-title">פתיחת חשבון חדש</h2>
                }

                {/* <button type='button' onClick={onFacebookLogin} className='main-btn long option facebook'>
                    <img src={facebook} alt="facebook icon" />
                    הרשמה עם Facebook
                </button> */}
                <button type='button' onClick={onGoogleLogin} className='main-btn long option'>
                    <img src={google} alt="google icon" />
                    הרשמה עם Google</button>
                <div className='separator'>
                    <div className='line'></div>
                    <p>או</p>
                    <div className='line'></div>
                </div>
                <Formik
                    onSubmit={onSubmit}
                    initialValues={{
                        mail: '',
                        password: '',
                        passwordConfirmation: '',
                    }}
                    enableReinitialize={true}
                    validationSchema={validationSignupPage}
                >
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <Field
                                className={props.touched.mail && Boolean(props.errors.mail) ? 'signup-input error' :
                                    emailRegex.test(props.values.mail) ? 'signup-input success' : 'signup-input'}
                                type='text'
                                id='mail'
                                name='mail'
                                placeholder='כתובת דוא"ל*'
                                onChange={props.handleChange}
                                value={props.values.mail}
                            />
                            <Field
                                className={props.touched.password && Boolean(props.errors.password) ? 'signup-input error' :
                                    props.values.password.length >= 8 ? 'signup-input success' : 'signup-input'}
                                type='password'
                                placeholder='סיסמה*'
                                id='password'
                                name='password'
                                value={props.values.password}
                                onChange={props.handleChange}
                            />
                            <Field
                                className={props.touched.passwordConfirmation && Boolean(props.errors.passwordConfirmation) ? 'signup-input error' :
                                    (props.values.passwordConfirmation === props.values.password && props.values.password.length >= 8) ? 'signup-input success' : 'signup-input'}
                                type='password'
                                placeholder='הזנת סיסמה בשנית*'
                                onChange={props.handleChange}
                                id='passwordConfirmation'
                                name='passwordConfirmation'
                                value={props.values.passwordConfirmation}
                            />
                            {props.values.password.length < 8 &&
                                <p>
                                    סיסמה צריכה להכיל לפחות 8 תווים
                                </p>
                            }
                            {errorAuth && <>
                                <p className='error-message'>{errorAuth}</p>
                                <p>להתחברות והשלמת ההרשמה <span onClick={login}>לחצו כאן</span></p>
                                <p>לאיפוס סיסמה <span onClick={onForgotPassword}>לחצו כאן</span></p>
                            </>}

                            <button type='submit' className='main-btn long register'>הרשמה</button>
                        </form>)}
                </Formik>
                <div className="security-note">
                    <div>
                        <img src={secure} alt="secure" />
                        <p>המידע שלכם שמור בבטחה</p>
                    </div>
                    <p>רכישה מאובטחת בתקן PCI DSS</p>
                </div>
            </div >
        </div>
    </>
};


