import React from 'react';
import { Field, Formik } from 'formik';
import { validationSetPasswordPage } from '../validations'
import { confirmPasswordReset } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { updateSystemData } from '../../../store/actions/systemActions'
import { ReactComponent as Close } from '../../../images/close.svg'
import { useLocation } from 'react-router-dom'
import { auth } from '../../../integrations/firebase'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const SetPassword = ({ oobCode }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const query = useQuery();
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const activeUser = useSelector((state) => state.userReducer.user)
    const code = oobCode || query.get('oobCode')
    const onSubmit = (values) => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'login' }
        }));
        confirmPasswordReset(auth, code, values.password)
            .then(() => {
                if (activeUser?.fullName) navigate('/');
                else navigate('/LoginSignup');
            }
            ).catch((error) => {
                const errorMessage = error.message;
                console.log('%c  errorMessage:', 'color: white;background: red;', errorMessage);
            });
    }
    const handleBack = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'loginOptions' }
        }));
    }
    const setModalClose = () => {
        navigate(systemData.isCheckout ? '/checkout' : '/')
    };

    return <>
        <div className="header-btns" style={{ 'justifyContent': 'flex-end' }}>
            {/*<Close onClick={setModalClose} />*/}
        </div>
        <div className="content">
            <Formik
                onSubmit={onSubmit}
                validateOnChange={true}
                validateOnBlur={true}
                validateOnMount={true}
                initialValues={{
                    password: '',
                    passwordConfirmation: ''
                }}
                enableReinitialize={true}
                validationSchema={validationSetPasswordPage}
            >
                {(props) => {
                    return <form onSubmit={props.handleSubmit}>
                        <div className="login-step forgot-password">
                            <div className="forgot-password-header">
                                <h2 className="main-title bold">איפוס סיסמה</h2>
                            </div>
                            <Field
                                className={props.touched.password && Boolean(props.errors.password) ? 'signup-input error' :
                                    props.values.password.length >= 8 ? 'signup-input success' : 'signup-input'}
                                type='password'
                                placeholder='סיסמה חדשה*'
                                id='password'
                                name='password'
                                value={props.values.password}
                                onChange={props.handleChange}
                            />
                            <Field
                                className={props.touched.passwordConfirmation && Boolean(props.errors.passwordConfirmation) ? 'signup-input error' :
                                    (props.values.passwordConfirmation === props.values.password && props.values.password.length >= 8) ? 'signup-input success' : 'signup-input'}
                                type='password'
                                placeholder='הזן סיסמה חדשה בשנית*'
                                onChange={props.handleChange}
                                id='passwordConfirmation'
                                name='passwordConfirmation'
                                value={props.values.passwordConfirmation}
                            />
                            <p>סיסמה צריכה להכיל לפחות 8 תווים</p>
                            <button type='submit' className='main-btn long register'>איפוס סיסמה</button>
                        </div>
                    </form>
                }}
            </Formik>
        </div>
    </>
};


