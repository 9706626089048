import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Field, Formik } from 'formik';
import { validationResetPassword } from '../validations'
import { useDispatch, useSelector } from 'react-redux'
import { updateSystemData } from '../../../store/actions/systemActions';
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Close } from '../../../images/close.svg'
import { ReactComponent as Back } from '../../../images/back-small.svg'
import { auth } from '../../../integrations/firebase'

export const EmailResetPassword = () => {
    const [email, setEmail] = useState('')
    const [errorAuth, setErrorAuth] = useState(null)
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onForgotPassword = (email) => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                dispatch(updateSystemData({
                    ...systemData, currLoginData: { currentStep: 'resetPasswordSent' }, resetPassEmailAddress: email
                }));
            })
            .catch((error) => {
                const errorMessage = error.message;
                console.log('%c  errorMessage:', 'color: white;background: red;', errorMessage);
                setErrorAuth('דוא״ל זה אינו קיים במערכת')
            });
    }

    const onSubmit = (values) => {
        onForgotPassword(values.email)
    }

    const setModalClose = () => {
        navigate(systemData.isCheckout ? '/checkout' : '/')
    };

    const handleBack = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'login' }
        }));
    }
    return <>
        <div className="header-btns">
            <button type="button" className="back-btn" onClick={handleBack}>
                <p>חזרה</p>
                <Back />
            </button>
            {/*<Close onClick={setModalClose} />*/}
        </div>
        <div className="content">
            <Formik
                onSubmit={onSubmit}
                validateOnChange={true}
                validateOnBlur={true}
                validateOnMount={true}
                initialValues={{
                    email: email
                }}
                enableReinitialize={true}
                validationSchema={validationResetPassword}
            >
                {(props) => {
                    return <form onSubmit={props.handleSubmit}>
                        <div className="login-step forgot-password">
                            <div className="forgot-password-header">
                                <h2 className="main-title bold">נשארתם בחוץ? כאן מאפסים</h2>
                                <p>הזינו את כתובת הדוא״ל שלכם לאיפוס סיסמה</p>
                            </div>
                            <Field
                                type='text'
                                className={props.touched.email && Boolean(props.errors.email) ? 'signup-input error' : 'signup-input'}
                                placeholder='דוא״ל לאיפוס סיסמה'
                                onChange={(e) => setEmail(e.target.value)}
                                name='email'
                                value={props.values.email}
                            />
                            {/* <p>לא קיבלתם מייל לאיפוס סיסמה? <span onClick={() => onForgotPassword(props.values.email)}>לחצו כאן לשליחה חוזרת</span></p> */}
                            {errorAuth && <p className="error-message">{errorAuth}</p>}
                            <button type='submit' className='main-btn long register'>איפוס סיסמה</button>
                        </div>
                    </form>
                }}
            </Formik>
        </div>
    </>
};


