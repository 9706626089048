import React from "react";
import closeIconWhite from "../../../images/closeIconWhite.svg";



const EmergencyMsgs = ({ setIsShowEmergencyMsgs }) => {
    return (
        <header className="header-container main-layout emergency-msgs">
            <p  className="">
                {"המערכת החדשה של דלוברי כבר זמינה!!    "}
                <a href={`${process.env.REACT_APP_NEW_SITE_URL}?mode=continueAsGuest`}>לחצו כאן כדי לעבור אליה</a>
            </p>
            <div className="close-icon" onClick={() => setIsShowEmergencyMsgs(false)}>
                <img src={closeIconWhite} alt="close" />
            </div>
        </header>
    );
};

export default EmergencyMsgs;
